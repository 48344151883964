@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothambolditalic-webfont.eot');
  src: url('assets/fonts/gothambolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothambolditalic-webfont.woff2') format('woff2'),
  url('assets/fonts/gothambolditalic-webfont.woff') format('woff'),
  url('assets/fonts/gothambolditalic-webfont.ttf') format('truetype'),
  url('assets/fonts/gothambolditalic-webfont.svg#gothambold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothambookitalic-webfont.eot');
  src: url('assets/fonts/gothambookitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothambookitalic-webfont.woff2') format('woff2'),
  url('assets/fonts/gothambookitalic-webfont.woff') format('woff'),
  url('assets/fonts/gothambookitalic-webfont.ttf') format('truetype'),
  url('assets/fonts/gothambookitalic-webfont.svg#gothambook_italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothammedium-webfont.eot');
  src: url('assets/fonts/gothammedium-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothammedium-webfont.woff2') format('woff2'),
  url('assets/fonts/gothammedium-webfont.woff') format('woff'),
  url('assets/fonts/gothammedium-webfont.ttf') format('truetype'),
  url('assets/fonts/gothammedium-webfont.svg#gothammedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothambold-webfont.eot');
  src: url('assets/fonts/gothambold-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothambold-webfont.woff2') format('woff2'),
  url('assets/fonts/gothambold-webfont.woff') format('woff'),
  url('assets/fonts/gothambold-webfont.ttf') format('truetype'),
  url('assets/fonts/gothambold-webfont.svg#gothambold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothambook-webfont.eot');
  src: url('assets/fonts/gothambook-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothambook-webfont.woff2') format('woff2'),
  url('assets/fonts/gothambook-webfont.woff') format('woff'),
  url('assets/fonts/gothambook-webfont.ttf') format('truetype'),
  url('assets/fonts/gothambook-webfont.svg#gothambook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothamlight-webfont.eot');
  src: url('assets/fonts/gothamlight-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothamlight-webfont.woff2') format('woff2'),
  url('assets/fonts/gothamlight-webfont.woff') format('woff'),
  url('assets/fonts/gothamlight-webfont.ttf') format('truetype'),
  url('assets/fonts/gothamlight-webfont.svg#gothamlight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothamlightitalic-webfont.eot');
  src: url('assets/fonts/gothamlightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothamlightitalic-webfont.woff2') format('woff2'),
  url('assets/fonts/gothamlightitalic-webfont.woff') format('woff'),
  url('assets/fonts/gothamlightitalic-webfont.ttf') format('truetype'),
  url('assets/fonts/gothamlightitalic-webfont.svg#gothamlight_italic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'gotham';
  src: url('assets/fonts/gothammedium_1-webfont.eot');
  src: url('assets/fonts/gothammedium_1-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/gothammedium_1-webfont.woff2') format('woff2'),
  url('assets/fonts/gothammedium_1-webfont.woff') format('woff'),
  url('assets/fonts/gothammedium_1-webfont.ttf') format('truetype'),
  url('assets/fonts/gothammedium_1-webfont.svg#gothammedium') format('svg');
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'gotham', sans-serif !important;
}

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
}
#root {
  min-height: 100vh;
}
h1 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
}
.base-grid-right {
  min-height: 100vh;
}
.page-container {
  width: calc(100% - 350px);
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 1% 1% 1%;
  box-sizing: border-box;
}
.page-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.page-content {
  width: 100%;
  height: 100%;
}
.page-filters {
  width: 100%;
}
.page-results {
  width: 100%;
}

.home-inner {
  margin: 0 auto;
  padding: 35px 0 0;
  max-width: 1320px;
  width: 100%;
  z-index: 5;
}
.home-text {
  color: #000;
  font-size: 24px;
}
.home-text span {
  text-align: center;
  display: block;
}
.home-text span:not(:last-child) {
  margin-bottom: 16px;
}
.home-buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.home-buttons button {
  max-width: 300px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: unset;
  line-height: 1;
  font-size: 14px !important;
}

.home-buttons .home-buttons-register {
  margin-right: 20px !important;
  background-color: #373632;
  border: 3px solid #373632;
}

.home-buttons .home-buttons-login {
  background-color: rgba(255, 255, 255, 0);
  border: 3px solid white;
}

.home-buttons-register {
  margin-right: 50px !important;
}
.static-wrapper {
  max-width: 870px;
  padding: 20px 20px 90px 20px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.static-wrapper .static-title {
  font-size: 24px;
  color: #373632;
  font-weight: bold;
}
.static-wrapper .static-subtitle {
  font-size: 18px;
  line-height: 35px;
  color: #373632;
  font-weight: 500;
}
.static-wrapper .static-about-description-columns {
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 55px;
}
.static-wrapper .static-about-description-first-column {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.static-wrapper
  .static-about-description-first-column
  .static-about-description-first-column-pretitle {
  color: #c19a5b;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}
.static-wrapper
  .static-about-description-first-column
  .static-about-description-first-column-title {
  color: #373632;
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}
.static-wrapper
  .static-about-description-first-column
  .static-about-description-first-column-text {
  color: #373632;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  text-align: justify;
}
.static-wrapper
  .static-about-description-first-column
  .static-about-description-first-column-text:not(:last-child) {
  margin-bottom: 20px;
}
.static-wrapper .static-about-description-second-column {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.static-wrapper .static-about-description-second-column img {
  width: 100%;
}
.static-wrapper .static-about-big-text {
  width: 100%;
  display: block;
  text-align: center;
  color: #373632;
  font-size: 26px;
  line-height: 42px;
  font-weight: normal;
}
.static-wrapper .static-about-bottom-columns {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.static-wrapper .static-about-bottom-column {
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.static-wrapper .static-about-bottom-column img {
  width: 100%;
  margin-bottom: 25px;
}
.static-wrapper .static-about-bottom-column-title {
  color: #c19a5b;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
.static-wrapper .static-about-bottom-column-text {
  color: #373632;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
button {
  color: white !important;
}
.join-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  max-width: 380px;
}
.join-title {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.join-subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 25px;
}
.join-wrapper form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 345px;
}
.join-instagram-button {
  margin-top: 20px !important;
  width: 100%;
  padding: 10px 0px !important;
}
.join-instagram-button img {
  max-width: 30px;
  width: 100%;
}
.join-divider {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 25px 0px;
}
.join-divider:before {
  content: "";
  position: absolute;
  width: 40%;
  background-color: gray;
  height: 1px;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.join-divider:after {
  content: "";
  position: absolute;
  width: 40%;
  background-color: gray;
  height: 1px;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}
.join-email-title {
  margin-bottom: 15px;
}

.referral-code-text-medium {
  font-weight: 500;
}
.referral-code-text {
  display: block;
  font-size: 14px;
  margin-bottom: 35px;
}
.referral-code-text a {
  font-size: 14px;
  color: #c19a5b;
}

.join-terms-text {
  font-size: 14px;
  margin-bottom: 30px;
}
.join-terms-text a {
  font-size: 14px;
  color: #c19a5b;
}
.join-bottom-text {
  font-size: 14px;
  margin-top: 20px;
}
.join-bottom-text a {
  font-size: 14px;
  color: #c19a5b;
  text-decoration: underline;
  cursor: pointer;
}

.create-invitation-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.create-invitation-header-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #d4b88c;
  font-size: 14px;
  font-weight: bold;
}
.create-invitation-header-counter .MuiCircularProgress-root {
  margin-right: 20px;
}

.create-invitation-body {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 90vh;
}

.create-invitation-body-form {
  margin: 0px auto;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.create-invitation-body-form-inner {
  padding: 0px 0px 20px 0px;
  width: 100%;
}
.create-invitation-body-form h3 {
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 0px;
}
.create-invitation-body-form h4 {
  font-size: 15px !important;
  font-weight: 600;
}
.create-invitation-body-form label {
  color: #373632 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 5px 0;
}

.create-invitation-body-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: auto;
}

.create-invitation-body-form .MuiButton-outlinedPrimary,
.create-invitation-body-buttons .MuiButton-outlinedPrimary {
  color: #c19a5b !important;
}

.location-input {
  margin-bottom: 30px !important;
}
.location-input .MuiOutlinedInput-notchedOutline {
  display: none;
}
.location-input .MuiInputBase-root {
  border-radius: 1px !important;
  border: 1px solid #c19a5b;
  padding: 0px !important;
}
.location-input .MuiInputBase-root.Mui-error {
  border: 1px solid #f44336 !important;
}
.location-input .MuiFormHelperText-root {
  font-size: 15px;
  color: #f44336;
  margin: 0px !important;
}
.location-input input {
  padding: 15px 20px !important;
  outline: unset !important;
}
.location-input input::placeholder {
  color: #000;
  font-size: 16px;
  opacity: 1;
}
.create-invitation-input,
.create-invitation-input-date,
.create-invitation-input-select {
  margin-bottom: 28px !important;
}
.create-invitation-input div,
.create-invitation-input-date div,
.create-invitation-input-select div {
  border-radius: 1px;
  border: solid 1px #c19a5b !important;
}
.create-invitation-input div input,
.create-invitation-input-date div input,
.create-invitation-input-select div input {
  outline: none !important;
}
.create-invitation-input-date input::-webkit-datetime-edit-fields-wrapper {
  font-weight: 400 !important;
  color: #000 !important;
}

.create-invitation-input div button {
  display: none !important;
}

.create-invitation-input label {
  position: absolute;
  top: -38px;
  margin-left: 0;
  left: -14px;
  padding: 0;
}
.create-invitation-input-date label {
  position: absolute;
  top: -24px;
  margin-left: 0;
  left: 0;
  padding: 0;
}

.create-invitation-input-date div input {
  padding: 18px;
  font-weight: 600;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../src/assets/dates_from-to.svg") no-repeat;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-position: center;
  background-size: contain;
  width: 16px;
  height: 16px;
  border-width: thin;
  padding: 0px;
}

.create-invitation-input-select div {
  padding: 18px;
}

.create-invitation-input-select div em {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  color: #9b9b9b;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #fff !important;
}
.step4-required-fields-text {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.step4-required-fields-text em {
  font-style: normal !important;
}
.step4-required-fields-text strong {
  font-weight: 900;
}

/* Date picker  */

.rdrDefinedRangesWrapper {
  display: none;
}

.daterangepicker-container:nth-child(2) {
  display: none;
}
.daterangepicker-container {
  display: flex;
  height: 370px;
}
.daterangepicker-right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0px 30px;
}
.daterangepicker-right-column .the-dates {
  font-size: 14px;
  color: #373632;
  font-weight: 600;
  text-align: center;
  border-bottom: solid 1px #c19a5b24;
  padding-bottom: 14px;
  padding-top: 2px;
}

.daterangepicker-right-column label {
  font-size: 12px;
  color: #373632;
  font-weight: 600;
  margin-bottom: -10px;
}

.daterangepicker-right-column input {
  width: 150px;
  height: 20px;
  font-size: 15px;
  padding: 15px 20px;
  border: solid 1px #c19a5b;
  visibility: hidden;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: unset !important;
  padding-top: 10px;
}
.rdrMonthAndYearPickers {
  display: none !important;
}
.rdrMonthName {
  font-weight: 500;
  font-size: 14px;
  color: #c19a5b !important;
  text-transform: uppercase !important;
  border-bottom: solid 1px #c19a5b24;
  padding: 10px 0px 20px 0px !important;
  margin-bottom: 20px;
}

.rdrMonth:first-child .rdrMonthName {
  margin-left: 30px;
}

.rdrDayToday .rdrDayNumber span:after {
  content: unset !important;
}
.rdrNextPrevButton {
  position: absolute;
  background: #fff;
  border-color: #c19a5b !important;
  top: 25px;
}
.rdrPprevButton {
  left: 0;
  background: transparent !important;
}
.rdrPprevButton:before {
  content: " <";
  font-weight: 600;
  font-size: 12px;
  color: #c19a5b !important;
}
.rdrNextButton {
  right: 264px;
  background: transparent !important;
}
.rdrNextButton:before {
  content: " >";
  font-weight: 600;
  font-size: 12px;
  color: #c19a5b !important;
}
.rdrNextButton i,
.rdrPprevButton i {
  display: none !important;
}
.rdrStartEdge,
.rdrEndEdge {
  left: 8px !important;
  top: 2px !important;
  border-radius: 100px !important;
  width: 32px;
  height: 32px;
  color: #c19a5b !important;
  z-index: 9;
}

.rdrDayNumber {
  z-index: 11;
  font-size: 15px !important;
}
.rdrDay:not(.rdrDayDisabled) .rdrDayNumber span {
  color: #373632 !important;
  font-weight: 500;
}
.rdrDay:not(.rdrDayDisabled) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayDisabled) .rdrEndEdge ~ .rdrDayNumber span {
  color: white !important;
}
.rdrDay.rdrDayPassive .rdrDayNumber span {
  color: #fff !important;
}
.rdrDayDisabled .rdrDayNumber span {
  color: #ffffff !important;
}
.rdrDayDisabled {
  background-color: #fff !important;
  cursor: default !important;
}
.rdrWeekDays span.rdrWeekDay {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #373632;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #373632 !important;
}
.rdrInRange {
  color: #f9f5ef !important;
  width: 81px;
  left: -32px !important;
  padding: 10px 0;
  height: 11px;
  margin-left: 14px;
  z-index: 1;
  top: 3px !important;
}
#submitDatesBtn {
  background: #c19a5b;
  height: 48px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
}
.MuiCalendarPicker-root .MuiPickersArrowSwitcher-button svg,
.MuiCalendarPicker-root .MuiIconButton-sizeSmall svg {
  fill: #c19a5b !important;
}
.MuiAutocomplete-endAdornment svg {
  fill: #c19a5b !important;
}
.daterangepicker-right-column input:disabled {
  cursor: default;
  background-color: #fff;
  color: #373632;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
  position: absolute !important;
  width: 168px !important;
  right: 63px;
  top: 110px;
  height: auto;
}

.rdrDateDisplay {
  justify-content: space-between;
  flex-direction: column !important;
  height: 158px;
  margin: 11px 9px 10px 9px !important;
}
.rdrDateDisplayItem input {
  height: 100% !important;
  color: #373632 !important;
  line-height: unset !important;
  font-size: 15px !important;
  font-weight: 500;
}
.rdrDateDisplayItem {
  border-radius: 0 !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 0 0 !important;
  width: 100% !important;
  width: 150px;
  max-height: 20px !important;
  font-size: 15px;
  padding: 15px 20px;
  border: solid 1px #c19a5b !important;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0 !important;
}

/* INBOX START */
.applicationSingleActive {
  background: #f9f5ef !important;
}
/* INBOX END */

@media screen and (max-width: 1024px) {
  .page-container {
    width: calc(100% - 260px);
  }
}
@media screen and (max-height: 600px) {
  .create-invitation-body-buttons {
    margin-bottom: 55px;
  }
}
@media screen and (max-width: 915px) {
  .page-container {
    width: 100%;
    margin: auto;
    /* margin-top: 60px; */
    margin: " 20px 20px";
  }
  .create-invitation-body {
    min-height: 80vh;
  }
  .create-invitation-body-buttons {
    margin-bottom: 20px;
  }
  .create-invitation-wrapper {
    margin-bottom: 50px;
  }
  .home-inner {
    padding: 35px 0 0;
  }
}

@media screen and (max-width: 700px) {
  .create-invitation-body-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px;
  }
  .static-wrapper .static-about-big-text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .create-invitation-body-buttons {
    margin-bottom: 80px;
  }

  .static-wrapper .static-about-bottom-columns {
    display: block;
    width: 100%;
    flex-direction: column;
  }

  .static-wrapper .static-about-bottom-column {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .static-wrapper .static-about-description-columns {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 55px;
  }

  .static-wrapper .static-about-description-first-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .static-wrapper .static-about-description-second-column {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .home-inner {
    padding: 0;
  }

  .home-text {
    font-size: 28px;
  }
  .home-text span:not(:last-child) {
    margin-bottom: 10px;
    display: block;
  }

  .home-buttons {
    flex-direction: column;
  }

  .home-buttons button {
    max-width: 100%;
  }

  .home-buttons .home-buttons-register {
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 400px) {
  .create-invitation-body-buttons {
    margin-bottom: 80px;
  }
}


.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
